import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: true,
    error: null,
    job: null,
}

const slice = createSlice({
    name: 'AtsJob',
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;

        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        fetchAtsJob(state, action) {
            state.loading = false;
            state.error = null;
            state.job = action.payload?.data?.data;

        },
        resetStateAtsJob(state) {
            state.loading = false;
            state.job = null;
            state.error = null;
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { fetchAtsJob } = slice.actions;

export function GetAtsJob(token, jobId, orgId) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateAtsJob());
        dispatch(slice.actions.startLoading());


        try {

            const response = await axios.get(`${DomainURL.BaseExternalURL}job?jobId=${jobId}&orgId=${orgId}`, { headers: headerParams(token) });

            dispatch(slice.actions.fetchAtsJob(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function resetAtsJob() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateAtsJob());
    }
}


