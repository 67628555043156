import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: true,
    error: null,
    successMessage:null,
    atsLinkToken: null,
}

const slice = createSlice({
    name: 'AtsPublicKeyPost',
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;

        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        fetchatsPublicKeyPost(state, action) {
            state.loading = false;
            state.error = null;
            state.successMessage = action.payload?.data.data;

        },
        resetStateatsPublicKeyPost(state) {
            state.loading = false;
            state.atsLinkToken = null;
            state.error = null;
            state.successMessage = null;
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { fetchAtsPublicKeyPost } = slice.actions;

export function AtsPublicKeyPost(token, data) {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateatsPublicKeyPost());
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${DomainURL.BaseExternalURL}merge-dev/account-token`, { ...data }, { headers: headerParams(token) });
            dispatch(slice.actions.fetchatsPublicKeyPost(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function resetatsPublicKeyPost() {
    return async (dispatch) => {
        dispatch(slice.actions.resetStateatsPublicKeyPost());
    }
}


