import { createSlice } from '@reduxjs/toolkit';
// config
import { DomainURL } from 'src/DomainURL';
// utils
import { headerParams } from 'src/utils/headers';
import axios from '../../../../../utils/axios';

// ----------------------------------------------------------------------
const initialState = {
    loading: false,
    error: null,
    empty: false,
    pipeline: null,
}

const slice = createSlice({
    name: 'atsJobPipeline',
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;
            state.pipeline = null;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getAtsJobPipeline(state, action) {
            state.loading = false;
            state.error = null;
            state.pipeline = action.payload;
        },
        resetState(state) {
            state.loading = false;
            state.pipeline = null;
            state.error = null
        }
    },

});

// Reducer
export default slice.reducer;

// Actions
export const { getAtsJobPipeline, resetState } = slice.actions;

export function GetAtsJobPipeline(token, jobId, status, pageNumber) {
    return async (dispatch) => {
        dispatch(slice.actions.resetState());
        dispatch(slice.actions.startLoading());
        let response = null
        try {
            if (status) {
                response = await axios.get(`${DomainURL.BaseExternalURLV2}pipeline/job/${jobId}?status=${status}&&pageNumber=${pageNumber}&&pageSize=10`, {
                    headers: headerParams(token),
                });
            } else {
                response = await axios.get(`${DomainURL.BaseExternalURLV2}pipeline/job/${jobId}?pageNumber=${pageNumber}&&pageSize=10`, {
                    headers: headerParams(token),
                });
            }

            dispatch(slice.actions.getAtsJobPipeline(response?.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error.data));
        }
    };
}

export function resetGetAtsJobPipeline() {
    return async (dispatch) => {
        dispatch(slice.actions.resetState());
    }
}