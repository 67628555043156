import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSettingsContext } from 'src/components/settings';
//
import { RegisterNotification } from 'src/redux/slices/common/notification-register';
import { dispatch, useSelector } from 'src/redux/store';
import { useEffect, useState } from 'react';
import { RegisterAlert } from 'src/redux/slices/common/notification-alert';
import { onMessage } from "firebase/messaging";
import { messaging } from 'src/components/notifications/push-notification';
import { ContactRequestMatricsCount } from 'src/redux/slices/common/contact-request-matrics';
import { EvMatricsCount } from 'src/redux/slices/common/employment-verification-matrics';
import { OrgProfileInfo } from 'src/redux/slices/employer/org-admin/company-info/company-info-get';
import Main from './main';
import Header from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const [deviceToken, setDeviceToken] = useState(localStorage.getItem('deviceToken'));


  const { token } = useSelector(state => state.auth)

  const { userPreview } = useSelector(state => state.userInfo)

  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;


  useEffect(() => {
    if (token && deviceToken) {
      dispatch(RegisterNotification(token, deviceToken))
    }
  }, [token, deviceToken])

  useEffect(() => {
    const liveSubscribe = onMessage(messaging, async remoteMessage => {
      dispatch(RegisterAlert(token))
      dispatch(ContactRequestMatricsCount(token))
      dispatch(EvMatricsCount(token))
    });

    return liveSubscribe;
  }, [token]);

  useEffect(() => {
    dispatch(OrgProfileInfo(token, userPreview?.organizationId))
  }, [token, userPreview?.organizationId])

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        {renderNavVertical}

        <Main>{children}</Main>
      </Box>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
