
import PropTypes from 'prop-types';
// store
import { useSelector } from 'src/redux/store';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

export default function PremiumGuard({ children, sx, hasContent }) {
    const { companyInfo, loading } = useSelector(state => state.orgCompanyInfo);
    const licenseType = companyInfo?.license || null;

    // Show splash screen if loading
    if (loading) {
        console.log("Loading... showing splash screen.");
        return <SplashScreen />;
    }

    // Redirect to 404 if the user doesn't have the required content
    if (hasContent) {
        console.log("Redirecting to /404 because role is not authorized.");
        window.location.assign('/404');
        return null;  // To prevent further rendering after redirection
    }

    // Check for the PREMIUM license type
    if (licenseType === 'PREMIUM') {
        return children; // Render the children if the license is PREMIUM
    }

    // Return null or a fallback when no conditions are met
    return null; // Or you could display a fallback UI, like a message or loading state
}

PremiumGuard.propTypes = {
    children: PropTypes.node,
    hasContent: PropTypes.bool,
    // roles: PropTypes.arrayOf(PropTypes.string),
    sx: PropTypes.object,
    // denied: PropTypes.string,
};
